import React, { useRef, forwardRef } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import './ResizeableContainer.less';
import ResizeAnchor, { Positions } from './ResizeAnchor';

const pkg = 'resizeable-container';
const ResizeableContainer = forwardRef(
  ({ resizeable, className, style, children, onResize, onResizeEnd }, ref) => {
    const resizeStateRef = useRef(false);

    const calculateMovement = (ev) => ({
      movementX: ev.screenX - resizeStateRef.current.screenX,
      movementY: ev.screenY - resizeStateRef.current.screenY,
    });

    const calculateDimensionUpdate = useCallback((ev) => {
      const { movementX, movementY } = calculateMovement(ev);
      const dimensionUpdate = {};
      switch (resizeStateRef.current.resizer) {
        case Positions.TOP_LEFT:
          dimensionUpdate.top = movementY;
          dimensionUpdate.left = movementX;
          dimensionUpdate.width = -movementX;
          dimensionUpdate.height = -movementY;
          break;
        case Positions.TOP_RIGHT:
          dimensionUpdate.top = movementY;
          dimensionUpdate.width = movementX;
          dimensionUpdate.height = -movementY;
          break;
        case Positions.BOTTOM_LEFT:
          dimensionUpdate.left = movementX;
          dimensionUpdate.width = -movementX;
          dimensionUpdate.height = movementY;
          break;
        case Positions.BOTTOM_RIGHT:
          dimensionUpdate.width = movementX;
          dimensionUpdate.height = movementY;
          break;

        default:
          break;
      }

      return dimensionUpdate;
    }, []);

    const onResizeStart = ({ screenX, screenY }, position) => {
      resizeStateRef.current = {
        resizer: position,
        screenX,
        screenY,
      };
    };

    const onBodyMouseMove = useCallback(
      (ev) => {
        if (!onResize || !resizeStateRef.current) {
          return;
        }

        onResize(calculateDimensionUpdate(ev));
      },
      [onResize, calculateDimensionUpdate]
    );

    const onBodyMouseUp = useCallback(
      (ev) => {
        if (!resizeStateRef.current) {
          return;
        }

        if (onResizeEnd) {
          onResizeEnd(calculateDimensionUpdate(ev));
        }

        resizeStateRef.current = null;
      },
      [calculateDimensionUpdate, onResizeEnd]
    );

    useEffect(() => {
      document.body.addEventListener('mousemove', onBodyMouseMove);
      document.body.addEventListener('mouseup', onBodyMouseUp);

      return () => {
        document.body.removeEventListener('mousemove', onBodyMouseMove);
        document.body.removeEventListener('mouseup', onBodyMouseUp);
      };
    }, [onBodyMouseMove, onBodyMouseUp]);

    const classNames = useMemo(() => [className, pkg].join(' '), [className]);

    if (!resizeable) {
      return <>{children}</>;
    }

    return (
      <div ref={ref} className={classNames} style={style}>
        <ResizeAnchor
          onResizeStart={onResizeStart}
          position={Positions.TOP_LEFT}
        />
        <ResizeAnchor
          onResizeStart={onResizeStart}
          position={Positions.TOP_RIGHT}
        />
        <ResizeAnchor
          onResizeStart={onResizeStart}
          position={Positions.BOTTOM_LEFT}
        />
        <ResizeAnchor
          onResizeStart={onResizeStart}
          position={Positions.BOTTOM_RIGHT}
        />
        {children}
      </div>
    );
  }
);

export default ResizeableContainer;
