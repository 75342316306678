/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const layer = createSlice({
  name: 'layer',
  initialState: {
    layers: [
      {
        id: 0,
        name: 'Base',
        hide: false,
        base: true,
        img: '/images/layer-1.png',
        top: 0,
        left: 0,
        width: 728,
        height: 728,
      },
      {
        id: 1,
        name: 'Logo',
        hide: false,
        img: '/images/layer-2.png',
        top: 424,
        left: 281,
        width: 155,
        height: 158,
      },
      {
        id: 2,
        name: 'Fake Text',
        hide: false,
        selected: true,
        img: '/images/layer-3.png',
        top: 180,
        left: 230,
        width: 290,
        height: 137,
      },
    ],
  },
  reducers: {
    addLayer: (state, { payload }) => {
      state.layers = [
        ...state.layers,
        {
          ...payload,
        },
      ];
    },
    changeName: (state, { payload: { id, name } }) => {
      const selectedLayer = state.layers.find((l) => l.id === id);
      selectedLayer.name = name;
    },
    toggleVisibilty: (state, { payload: id }) => {
      const selectedLayer = state.layers.find((l) => l.id === id);

      if (!selectedLayer.base) selectedLayer.hide = !selectedLayer.hide;
    },
    dimensionUpdate: (
      state,
      {
        payload: {
          id,
          dim: { top, left, width, height },
        },
      }
    ) => {
      const layer = state.layers.find((l) => l.id === id);
      layer.top += top || 0;
      layer.left += left || 0;
      layer.width += width || 0;
      layer.height += height || 0;
    },
    setSelected: (state, { payload: id }) => {
      const currentlySelected = state.layers.find((layer) => layer.selected);
      if (currentlySelected) {
        currentlySelected.selected = false;
      }

      const toSelect = state.layers.find((l) => l.id === id);
      console.log({ toSelect, id });
      if (toSelect) {
        toSelect.selected = true;
      }
    },
    deleteLayer: (state, { payload: id }) => {
      state.layers = state.layers.filter((l) => l.id !== id);
    },
    reorderLayers: (state, { payload: reordered }) => {
      if (
        !Array.isArray(reordered) ||
        reordered.length !== state.layers.length
      ) {
        console.warn('No layers received in reorder reducer', { reordered });
        return;
      }

      state.layers = reordered;
    },
  },
});

export const {
  addLayer,
  deleteLayer,
  toggleVisibilty,
  changeName,
  reorderLayers,
  dimensionUpdate,
  setSelected,
} = layer.actions;

export const selectLayers = (state) => state.layer.layers;

export default layer.reducer;
