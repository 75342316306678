import React from 'react';
import 'antd/dist/antd.less';

import Main from './components/main/Main';

function App() {
  return <Main />;
}

export default App;
