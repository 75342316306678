import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Upload, message, Button, Typography } from 'antd';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { toBase64 } from '../../app/utils';

import {
  selectLayers,
  addLayer,
  deleteLayer,
  toggleVisibilty,
  reorderLayers,
  setSelected,
  changeName,
} from '../../slices/layer';
import './Layers.less';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const imageFormates = ['image/x-png', 'image/gif', 'image/jpeg'];

const { Paragraph } = Typography;
const pkg = 'layers';
const Layers = () => {
  const dispatch = useDispatch();
  const layers = useSelector(selectLayers);

  const onDelete = (id) => {
    dispatch(deleteLayer(id));
  };

  const onToggle = (id) => {
    dispatch(toggleVisibilty(id));
  };

  const onRename = (id, name) => {
    dispatch(changeName({ id, name }));
  };

  const onSelected = (id) => {
    dispatch(setSelected(id));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    dispatch(
      reorderLayers(
        reorder(layers, result.source.index, result.destination.index)
      )
    );
  };

  const onLayerAdd = (img) => {
    dispatch(
      addLayer({
        id: layers.length,
        name: `Layer ${layers.length}`,
        hide: false,
        base: false,
        top: 0,
        left: 0,
        img,
      })
    );

    message.success('New layer added successfuly');
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <LayersHeader onLayerAdd={onLayerAdd} title="Layers" />
            <LayersList
              layers={layers}
              onRename={onRename}
              onDelete={onDelete}
              onToggle={onToggle}
              onSelected={onSelected}
              DragPlaceHolder={provided.placeholder}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const LayersHeader = ({ title, onLayerAdd }) => {
  const beforeUpload = async (file) => {
    const imgBase64 = await toBase64(file);
    onLayerAdd(imgBase64);
    return false;
  };

  return (
    <div className={`${pkg}-header`}>
      <div className={`${pkg}-header-title`}>{title}</div>
      <Upload
        accept={imageFormates.join(',')}
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        <Button>
          <PlusOutlined /> New
        </Button>
      </Upload>
    </div>
  );
};

const LayersList = ({
  layers,
  onRename,
  onDelete,
  onToggle,
  onSelected,
  DragPlaceHolder,
}) => (
  <div className={`${pkg}-list`}>
    {layers.map((item, index) => (
      <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Layer
              item={item}
              onRename={onRename}
              onDelete={onDelete}
              onToggle={onToggle}
              onSelected={onSelected}
            />
          </div>
        )}
      </Draggable>
    ))}
    {DragPlaceHolder}
  </div>
);

const Layer = (props) => {
  const {
    item: { id, name, hide, base, selected },
    onDelete,
    onToggle,
    onRename,
    onSelected,
  } = props;

  return (
    <div
      onClick={() => onSelected(id)}
      className={`${pkg}-item ${selected ? 'selected' : ''}`}
    >
      <ToggleIcon hide={hide} base={base} onToggle={() => onToggle(id)} />
      <LayerName name={name} onRename={(value) => onRename(id, value)} />
      <DeleteIcon onDelete={() => onDelete(id)} />
    </div>
  );
};

const ToggleIcon = ({ hide, base, onToggle }) => {
  const Icon = hide ? EyeInvisibleOutlined : EyeOutlined;

  return (
    <Icon
      onClick={onToggle}
      className={`${pkg}-item-show ${pkg}-item-control ${base ? 'base' : ''}`}
    />
  );
};

const LayerName = ({ name, onRename }) => (
  <Paragraph
    className={`${pkg}-item-name`}
    editable={{
      icon: (
        <EditOutlined
          className={`${pkg}-item-control ${pkg}-item-control-edit`}
        />
      ),
      onChange: (value) => onRename(value),
    }}
  >
    {name}
  </Paragraph>
);

const DeleteIcon = ({ onDelete }) => (
  <DeleteOutlined
    onClick={onDelete}
    className={`${pkg}-item-delete ${pkg}-item-control`}
  />
);

export default Layers;
