import React from 'react';
import './Generator.less';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import domtoimage from 'dom-to-image';

import Preview from '../preview/Preview';
import Layers from '../layers/Layers';

const pkg = 'main';
const Generator = () => {
  const onDownloadClick = () => {
    domtoimage
      .toBlob(document.querySelector('.preview'))
      .then((blob) => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = 'generated-image.png';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })
      .catch((ex) => {
        console.log('Error while downloading image', ex);
      });
  };

  return (
    <div className={`${pkg}-container`}>
      <div className={`${pkg}-content`}>
        <Preview />
        <Layers />
      </div>
      <div className={`${pkg}-footer`}>
        <Button
          onClick={onDownloadClick}
          type="primary"
          size="large"
          icon={<DownloadOutlined />}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default Generator;
