import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { useMemo } from 'react';
import './DragableContainer.less';

const emtpyPng = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
const pkg = 'dragable-container';
const DragableContainer = forwardRef(
  (
    {
      dragable,
      className,
      style,
      children,
      onDrag: parentOnDrag,
      onDragEnd: parentOnDragEnd,
    },
    ref
  ) => {
    const dragStateRef = useRef(false);

    const [dragImg, setDragImg] = useState(null);

    useEffect(() => {
      const img = new Image();
      img.src = emtpyPng;
      setDragImg(img);
    }, []);

    const calculateMovement = (ev) => ({
      left: ev.screenX - dragStateRef.current.screenX,
      top: ev.screenY - dragStateRef.current.screenY,
    });

    const onDragStart = (ev) => {
      ev.dataTransfer.setDragImage(dragImg, 0, 0);

      if (!dragable) {
        return;
      }

      ev.target.style.opacity = 0.8;

      dragStateRef.current = {
        screenX: ev.screenX,
        screenY: ev.screenY,
      };
    };

    const onDrag = (ev) => {
      if (!parentOnDrag || !dragStateRef.current) {
        return;
      }

      parentOnDrag(calculateMovement(ev));
    };

    const onDragEnd = (ev) => {
      ev.target.style.opacity = 1;
      if (!parentOnDragEnd || !dragStateRef.current) {
        return;
      }

      parentOnDragEnd(calculateMovement(ev));
      dragStateRef.current = null;
    };

    const classNames = useMemo(() => [pkg, className].join(' '), [className]);

    if (!dragable) {
      return <>{children}</>;
    }

    return (
      <div
        dragable={dragable}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        ref={ref}
        className={classNames}
        style={style}
      >
        {children}
      </div>
    );
  }
);

export default DragableContainer;
