import React from 'react';
import { forwardRef } from 'react';

import './ResizeAnchor.less';

const ResizeAnchor = forwardRef(({ position, onResizeStart }, ref) => {
  const onMouseDown = (e) => {
    if (onResizeStart) {
      onResizeStart(e, position, ref);
    }
  };
  return (
    <div
      ref={ref}
      onMouseDown={onMouseDown}
      className={`resize-anchor resize-anchor-${position}`}
    ></div>
  );
});

export const Positions = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

export default ResizeAnchor;
