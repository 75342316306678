import React, { useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { dimensionUpdate, setSelected, selectLayers } from '../../slices/layer';
import DragableContainer from '../draggable/DragableContainer';

import ResizeableContainer from '../resizer/ResizeableContainer';

import './Preview.less';

const pkg = 'preview';
const Preview = () => {
  const layers = useSelector(selectLayers);

  return (
    <div className={`${pkg}`}>
      {layers.map((layer) => (
        <PreviewLayer layer={layer} />
      ))}
    </div>
  );
};

const PreviewLayer = ({
  layer: { id, name, img, hide, selected, top, left, width, height },
}) => {
  const dispatch = useDispatch();
  const layerRef = useRef(null);

  const style = useMemo(
    () => ({
      top,
      left,
      width: `${width}px`,
      height: `${height}px`,
    }),
    [top, left, width, height]
  );

  const onDimensionsUpdate = ({
    top: topUpdate,
    left: leftUpdate,
    width: widthUpdate,
    height: heightUpdate,
  }) => {
    layerRef.current.style.top = `${top + (topUpdate || 0)}px`;
    layerRef.current.style.left = `${left + (leftUpdate || 0)}px`;
    layerRef.current.style.width = `${width + (widthUpdate || 0)}px`;
    layerRef.current.style.height = `${height + (heightUpdate || 0)}px`;
  };

  const onResize = (dim) => onDimensionsUpdate(dim);
  const onDrag = (dim) => onDimensionsUpdate(dim);

  const onDragEnd = (dim) => dispatch(dimensionUpdate({ id, dim }));
  const onResizeEnd = (dim) => dispatch(dimensionUpdate({ id, dim }));

  const onContentClick = () => dispatch(setSelected(id));

  return !hide ? (
    <div
      ref={layerRef}
      style={style}
      className={`preview-layer ${selected ? 'selected' : ''}`}
    >
      <DragableContainer
        dragable={selected}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
      >
        <ResizeableContainer
          resizeable={selected}
          onResize={onResize}
          onResizeEnd={onResizeEnd}
        >
          <img
            onClick={onContentClick}
            className="preview-content"
            alt={name}
            src={img}
          />
        </ResizeableContainer>
      </DragableContainer>
    </div>
  ) : null;
};

export default Preview;
